import React from 'react';

const Feature = ({ feature, url }) => {
    return (
        <section className='flex flex-col items-center max-w-screen-lg px-4 mb-20 md:mx-auto md:px-0'>
            <h2 className='text-4xl font-bold text-rickshaw-blue'>{feature.name}</h2>
            <img src={url.publicURL} alt={feature.name} className="my-8" height={200} />
            <ul className='flex flex-col gap-x-10 md:flex-row'>
                {feature.content.map((content) => (
                    <li className="w-full mb-8 lg:w-1/3 md:mb-0" key={content.title}>
                        <h3 className='mb-2 text-xl font-bold text-rickshaw-blue'>{content.title}</h3>
                        <p className='text-gray-500 '>{content.content}</p>
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default Feature;
