import React from 'react'
import { graphql } from 'gatsby'
import Feature from '../components/Feature'
import PricingBanner from '../components/PricingBanner'
import Layout from '../components/Layout'
import Seo from "../components/Seo"
import features from '../content/features.json'

export const data = graphql`
    query {
        Pickup: file(relativePath: { eq: "pickup.svg" }) {
            publicURL
        }
        Delivery: file(relativePath: { eq: "delivery.svg" }) {
            publicURL
        }
        Package: file(relativePath: { eq: "package.svg" }) {
            publicURL
        }
        Reporting: file(relativePath: { eq: "report.svg" }) {
            publicURL
        }
    }
`;

const Features = ({ data }) => {
    return (
        <Layout>
            <Seo 
                title="Features"
                description="Everything you need for the last mile. Rickshaw is a secure and fault tolerant way to delivery payment related messagess"
            />
            <div className='flex items-center justify-center'>
                <h1 className="my-8 text-4xl font-bold text-center md:my-12 text-rickshaw-blue">
                    Everything you need for the last mile
                </h1>
            </div>
            <section className='features'>
                {features.map((feature) => (
                    <Feature key={feature.name} feature={feature} url={data[feature.name]} />
                ))}
            </section>
            <PricingBanner />
        </Layout>
    );
};

export default Features;
